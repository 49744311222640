<template>
  <tr class="title">
    <td :colspan="expanded ? null : this.channels.length + 1">
      <RowTitle
        :name="name"
        :expanded="expanded"
        @click="this.expanded = !this.expanded" />
    </td>
    <td v-if="!expanded" class="red">
      {{ formatMoney(this.cancellations.total * -1) }}
    </td>
    <template v-else>
      <td v-for="channel in this.channels" :key="channel" class="column-title">
        {{ formatChannel(channel) }}
      </td>
      <td class="column-title">Total</td>
    </template>
  </tr>
  <Transition name="fade">
    <tr v-if="expanded" class="detail">
      <td :colspan="this.channels.length + 2">
        <table>
          <tbody>
            <tr>
              <td :colspan="this.channels.length + 1">Card</td>
              <td class="red">
                {{ formatMoney(this.cancellations.card * -1) }}
              </td>
            </tr>
            <tr class="secondary">
              <td :colspan="this.channels.length + 1">
                (from Integrated card reader)
              </td>
              <td class="red">
                {{ formatMoney(this.cancellations.cardIntegrated * -1) }}
              </td>
            </tr>
            <tr class="secondary">
              <td :colspan="this.channels.length + 1">
                (from External card reader)
              </td>
              <td class="red">
                {{ formatMoney(this.cancellations.cardExternal * -1) }}
              </td>
            </tr>
            <tr>
              <td :colspan="this.channels.length + 1">Cash</td>
              <td class="red">
                {{ formatMoney(this.cancellations.cash * -1) }}
              </td>
            </tr>
            <tr>
              <td :colspan="this.channels.length + 1">Promotion</td>
              <td class="red">
                {{ formatMoney(this.cancellations.promotion * -1) }}
              </td>
            </tr>
            <tr class="divide">
              <td :colspan="this.channels.length + 2"><hr /></td>
            </tr>
            <tr>
              <td :colspan="this.channels.length + 1">Customer</td>
              <td class="red">
                {{ formatMoney(this.cancellations.customer * -1) }}
              </td>
            </tr>
            <tr>
              <td :colspan="this.channels.length + 1">Event</td>
              <td class="red">
                {{ formatMoney(this.cancellations.event * -1) }}
              </td>
            </tr>
            <tr>
              <td :colspan="this.channels.length + 1">Service</td>
              <td class="red">
                {{ formatMoney(this.cancellations.service * -1) }}
              </td>
            </tr>
            <tr>
              <td :colspan="this.channels.length + 1">Vendor</td>
              <td class="red">
                {{ formatMoney(this.cancellations.vendor * -1) }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td :colspan="this.channels.length + 1">Total</td>
              <td class="red">
                {{ formatMoney(this.cancellations.total * -1) }}
              </td>
            </tr>
          </tfoot>
        </table>
      </td>
    </tr>
  </Transition>
</template>

<script>
  import { mdiChevronDown } from "@mdi/js";
  import { formatChannel } from "@tucktrucks/platform-base-private";
  import RowTitle from "@/components/ServiceStatements/RowTitle.vue";

  export default {
    data() {
      return {
        expanded: false,
        mdiChevronDown,
        formatChannel,
      };
    },
    props: {
      name: {
        type: String,
        default: "",
      },
      cancellations: {
        type: Object,
        default: () => {},
      },
      channels: {
        type: Array,
        default: () => [],
      },
    },
    components: { RowTitle },
  };
</script>

<style lang="scss" scoped>
  tr {
    width: 100%;

    th,
    td {
      width: 15%;
      text-align: right;

      &:first-child {
        width: 30%;
        text-align: left;
      }
      &.column-title {
        font-size: 0.75rem;
        color: rgba($col_beta, 0.7);
      }
    }
    &.detail {
      table {
        @include striped-row-table;
        width: 100%;
        border-top: $table_border;
        border-bottom: $table_border;
        padding: 0.5rem 0;
        margin-bottom: 2rem;

        tfoot {
          font-weight: bold;
        }
      }
    }
  }

  tr.secondary {
    font-size: 0.8rem;
    color: #666;

    td,
    th {
      padding-left: 2rem;
    }
  }
</style>
