<template>
  <tr class="title">
    <td>
      <RowTitle
        :name="name"
        :expanded="expanded"
        @click="this.expanded = !this.expanded" />
    </td>
    <template v-if="!expanded">
      <td v-for="channel in this.channels" :key="channel">
        {{
          formatMoney(
            this.byChannel?.filter((x) => x.channel == channel)[0]?.total ?? 0
          )
        }}
      </td>
      <td>{{ formatMoney(this.total.total) }}</td>
    </template>
    <template v-else>
      <td v-for="channel in this.channels" :key="channel">
        {{ formatChannel(channel) }}
      </td>
      <td class="column-title">Total</td>
    </template>
  </tr>
  <Transition name="fade">
    <tr v-if="expanded" class="detail">
      <td :colspan="this.channels.length + 2">
        <table>
          <tbody>
            <tr>
              <td>Card</td>
              <td v-for="channel in this.channels" :key="channel">
                {{
                  formatMoney(
                    this.byChannel?.filter((x) => x.channel == channel)[0]
                      ?.card ?? 0
                  )
                }}
              </td>
              <td>{{ formatMoney(this.total.card) }}</td>
            </tr>
            <tr class="secondary">
              <td>(from Integrated card reader)</td>
              <td v-for="channel in this.channels" :key="channel">
                {{
                  formatMoney(
                    this.byChannel?.filter((x) => x.channel == channel)[0]
                      ?.cardIntegrated ?? 0
                  )
                }}
              </td>
              <td>{{ formatMoney(this.total.cardIntegrated) }}</td>
            </tr>
            <tr class="secondary">
              <td>(from External card reader)</td>
              <td v-for="channel in this.channels" :key="channel">
                {{
                  formatMoney(
                    this.byChannel?.filter((x) => x.channel == channel)[0]
                      ?.cardExternal ?? 0
                  )
                }}
              </td>
              <td>{{ formatMoney(this.total.cardExternal) }}</td>
            </tr>
            <tr>
              <td>Cash</td>
              <td v-for="channel in this.channels" :key="channel">
                {{
                  formatMoney(
                    this.byChannel?.filter((x) => x.channel == channel)[0]
                      ?.cash ?? 0
                  )
                }}
              </td>
              <td>{{ formatMoney(this.total.cash) }}</td>
            </tr>
            <tr>
              <td>Promotion</td>
              <td v-for="channel in this.channels" :key="channel">
                {{
                  formatMoney(
                    this.byChannel?.filter((x) => x.channel == channel)[0]
                      ?.promotion ?? 0
                  )
                }}
              </td>
              <td>{{ formatMoney(this.total.promotion) }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>Total</td>
              <td v-for="channel in this.channels" :key="channel">
                {{
                  formatMoney(
                    this.byChannel?.filter((x) => x.channel == channel)[0]
                      ?.total ?? 0
                  )
                }}
              </td>
              <td>{{ formatMoney(this.total.total) }}</td>
            </tr>
          </tfoot>
        </table>
      </td>
    </tr>
  </Transition>
</template>

<script>
  import { mdiChevronDown } from "@mdi/js";
  import { formatChannel } from "@tucktrucks/platform-base-private";
  import RowTitle from "@/components/ServiceStatements/RowTitle.vue";

  export default {
    data() {
      return {
        expanded: false,
        mdiChevronDown,
        formatChannel,
      };
    },

    props: {
      name: {
        type: String,
        default: "",
      },
      total: {
        type: Object,
        default: () => {},
      },
      channels: {
        type: Array,
        default: () => [],
      },
      byChannel: {
        type: Object,
        default: () => {},
      },
    },

    components: {
      RowTitle,
    },
  };
</script>

<style lang="scss" scoped>
  tr {
    width: 100%;

    th,
    td {
      width: 15%;
      text-align: right;

      &:first-child {
        width: 30%;
        text-align: left;
      }
      &.column-title {
        font-size: 0.75rem;
        color: rgba($col_beta, 0.5);
      }
    }

    &.detail {
      table {
        @include striped-row-table;
        width: 100%;
        border-top: $table_border;
        border-bottom: $table_border;
        padding: 0.5rem 0;
        margin-bottom: 2rem;

        tfoot {
          font-weight: bold;
        }
      }
    }
  }

  tr.secondary {
    font-size: 0.8rem;
    color: #666;

    td,
    th {
      padding-left: 2rem;
    }
  }
</style>
