<template>
  <h3 class="service-statement_row-title">
    <span class="name">
      {{ name }}
    </span>
    <Icon
      :path="mdiChevronDown"
      :class="{ collapsed: !expanded, expanded: expanded }"
      @click="$emit('click')" />
  </h3>
</template>

<script>
  import { mdiChevronDown } from "@mdi/js";

  export default {
    data() {
      return { mdiChevronDown };
    },
    props: {
      name: {
        type: String,
      },
      expanded: {
        type: Boolean,
      },
    },
    emits: ["click"],
  };
</script>
<style lang="scss" scoped>
  h3.service-statement_row-title {
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
    @include flex-initial;

    .mdi-icon {
      display: inline-block;
      cursor: pointer;

      &.expanded {
        transform: rotate(180deg);
      }
    }
  }
</style>
